import { Component, OnInit } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import pt from '@angular/common/locales/pt';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  currentNewsIndex = -1;
  currentNews = null;
  nextNewsUpdater = null;
  newsList = [];
  listWeather = [];
  newsApiCallTimeout = 3600000;
  currencyApiCallTimeout = 3600000; // 1 hour
  //currencyWeatherTimeout = 3700000;
  currentDescriptionValor = null;
  currentDescriptionPercentual = null;
  currentDescriptionNameStock = null;
  currentDescriptionQuotation1 = null;
  currentDescriptionQuotation2 = null;
  currentConvertCotation1 = null;
  currentConvertCotation2 = null;
  currentImageCotation2 = null;
  currentImageCotation1 = null;
  currentSymbolCotation = null;
  currentFormatDate = null;
  currentFormatHour = null;
  currentAMPM = Date();
  currentWeather1 = null;
  currentWeather2 = null;
  currentWeather3 = null;
  currentNameWeather1 = null;
  currentNameWeather2 = null;
  currentNameWeather3 = null;
  currentCountry = null;
  currentHiddenLogoTVBemLigados = false;
  currentHiddenLogoServierTv = false;
  currentCotation1 = null;
  currentCotation2 = null;
  currentValor = null;
  currentExtensao = null;
  currentPercentual = null;
  currentNewsScreen = null;
  currentLocale = null;
  currentTimeZone = null;
  currentDate = new Date();
  

  constructor(private http: HttpClient) {}

  loadNextNews() {  
    console.log('COUNTRY CODE: '+ this.currentCountry);
    let next_news_time = 120000;

    if(this.newsList == null || this.newsList.length == 0)
    {
      //this.currentNewsScreen = 'https://serviernews-dev.azurewebsites.net/SVAImagens/SemNoticia.jpg';
      this.currentNewsScreen = 'https://serviertv-admin.br1.servier.com/SVAImagens/SemNoticia.jpg';
    }else{
      this.currentNewsIndex++;
      this.currentNews = this.newsList[this.currentNewsIndex % this.newsList.length];
      console.log('extensao:' + this.currentNews.Diretorio.split('.').pop())
      this.currentExtensao = this.currentNews.Diretorio.split('.').pop();
      //this.currentNewsScreen = 'https://serviernews-dev.azurewebsites.net/NoticiasTv/' + this.newsList[this.currentNewsIndex % this.newsList.length].Arquivo;
      this.currentNewsScreen = 'https://serviertv-admin.br1.servier.com/NoticiasTv/' + this.newsList[this.currentNewsIndex % this.newsList.length].Arquivo;
      next_news_time = this.currentNews.Tempo;
    }
    
    console.log('troca url - ' + this.currentNewsScreen);
    const that = this;
    //that.loadDescriptionStock()
    //this.loadStock();
    this.nextNewsUpdater = setTimeout(() => {
      that.loadNextNews();
      console.log('trocando imagem');
    }, next_news_time);
  }

  loadCurrecyCotation1() {
    // peguei essa API de cotação daqui https://www.currencyconverterapi.com é grátis por 100 chamadas por hora, e os dados são atualizados a cada 1 hora
    //this.http.get('https://free.currencyconverterapi.com/api/v6/convert?q=EUR_BRL,USD_BRL&compact=ultra&apiKey=5a84732cc90adfbb647b').subscribe((data) => {
      this.http.get('https://serviertv-api.br1.servier.com/api/Noticia/GetCotation1/' + window.location.href.split('?ID=').pop()).subscribe((data) => {
      //this.http.get('https://serviernewsapi-dev.azurewebsites.net/api/Noticia/GetCotation1/' + window.location.href.split('?ID=').pop()).subscribe((data) => {
      
      if(data != null){
        console.log('COUNTRY CODE: '+ this.currentCountry);
        if(this.currentCountry == "KZ1"){
          this.currentSymbolCotation = "₸";
        }

        this.currentCotation1 = data['bid'];        
        
        console.log(data);
        console.log(':) 1 - ' + this.currentCotation1);
      }

    }, (e) => {
      console.log(e);
      console.log(' Error :( ' + this.currentCotation1);
    });
  }

  loadCurrecyCotation2() {    
      this.http.get('https://serviertv-api.br1.servier.com/api/Noticia/GetCotation2/' + window.location.href.split('?ID=').pop()).subscribe((data) => {
      //this.http.get('https://serviernewsapi-dev.azurewebsites.net/api/Noticia/GetCotation2/' + window.location.href.split('?ID=').pop()).subscribe((data) => {
      if(data != null){
        console.log('COUNTRY CODE: '+ this.currentCountry);
        if(this.currentCountry == "KZ1"){
          this.currentSymbolCotation = "₸";
        }
        this.currentCotation2 = data['bid'];
        console.log(data);
        console.log(':) 2 - ' + this.currentCotation2);
      }
    }, (e) => {
      console.log(e);
      console.log(' Error :( ' + this.currentCotation2);
    });
  }

  loadWeather() {
      this.http.get('https://serviertv-api.br1.servier.com/api/Noticia/GetWeather/' + window.location.href.split('?ID=').pop()).subscribe((data) => {
      //this.http.get('https://serviernewsapi-dev.azurewebsites.net/api/Noticia/GetWeather/' + window.location.href.split('?ID=').pop()).subscribe((data) => {
      this.currentWeather1 = data['weather1'];
      this.currentWeather2 = data['weather2'];
      this.currentWeather3 = data['weather3'];
      console.log(data);
      console.log(':)');
    }, (e) => {
      console.log(e);
      console.log(':(');
    });
  }

  loadNameWeather() {
    this.http.get('https://serviertv-api.br1.servier.com/api/Noticia/GetNameWeather/' + window.location.href.split('?ID=').pop()).subscribe((data) => {
    //this.http.get('https://serviernewsapi-dev.azurewebsites.net/api/Noticia/GetNameWeather/' + window.location.href.split('?ID=').pop()).subscribe((data) => {
      this.currentNameWeather1 = data['weather1'];
      this.currentNameWeather2 = data['weather2'];
      this.currentNameWeather3 = data['weather3'];
      console.log(data);
      console.log(':)');
    }, (e) => {
      console.log(e);
      console.log(':(');
    });
  }

  loadCountry() {
    this.http.get('https://serviertv-api.br1.servier.com/api/Noticia/GetCountry/' + window.location.href.split('?ID=').pop()).subscribe((data) => {
    //this.http.get('https://serviernewsapi-dev.azurewebsites.net/api/Noticia/GetCountry/' + window.location.href.split('?ID=').pop()).subscribe((data) => {
        this.currentCountry = data['countryCode'];
        console.log('Pais - ' + this.currentCountry + '-' + this.currentCountry == 'BR');
        console.log(':)');

        if(this.currentCountry == 'BR'){
          this.currentHiddenLogoTVBemLigados = true;
          this.currentHiddenLogoServierTv = false;
        }else{
          this.currentHiddenLogoTVBemLigados = false;
          this.currentHiddenLogoServierTv = true;
        }      
    }, (e) => {
      console.log(e);
      console.log(':(');
    });
  }

  loadConvertCotation1() {
    this.http.get('https://serviertv-api.br1.servier.com/api/Noticia/GetCotationConvertFor1/' + window.location.href.split('?ID=').pop()).subscribe((data) => {
    //this.http.get('https://serviernewsapi-dev.azurewebsites.net/api/Noticia/GetCotationConvertFor1/' + window.location.href.split('?ID=').pop()).subscribe((data) => {
      this.currentConvertCotation1 = data['convertFor1'];
      console.log(':)');
    }, (e) => {
      console.log(e);
      console.log(':(');
    });
  }

  loadConvertCotation2() {
    this.http.get('https://serviertv-api.br1.servier.com/api/Noticia/GetCotationConvertFor2/' + window.location.href.split('?ID=').pop()).subscribe((data) => {
      //this.http.get('https://serviernewsapi-dev.azurewebsites.net/api/Noticia/GetCotationConvertFor2/' + window.location.href.split('?ID=').pop()).subscribe((data) => {
      this.currentConvertCotation2 = data['convertFor2'];
      console.log(':)');
    }, (e) => {
      console.log(e);
      console.log(':(');
    });
  }

  loadDate() {
    this.http.get('https://serviertv-api.br1.servier.com/api/Noticia/GetDate/' + window.location.href.split('?ID=').pop()).subscribe((data) => {
      //this.http.get('https://serviernewsapi-dev.azurewebsites.net/api/Noticia/GetDate/' + window.location.href.split('?ID=').pop()).subscribe((data) => {
      this.currentTimeZone = data['timeZone'];
      this.currentLocale = data['locale'];
      this.currentDate = new Date(this.currentDate.toLocaleString(this.currentLocale, {timeZone: this.currentTimeZone}));
      console.log(':)');
    }, (e) => {
      console.log(e);
      console.log(':(');
    });
  }

  loadDescriptionStock() {    
    this.http.get('https://serviertv-api.br1.servier.com/api/Noticia/GetDescriptionStock/' + window.location.href.split('?ID=').pop()).subscribe((data: any[]) => {
      //this.http.get('https://serviernewsapi-dev.azurewebsites.net/api/Noticia/GetDescriptionStock/' + window.location.href.split('?ID=').pop()).subscribe((data: any[]) => {
      this.currentDescriptionValor = data['valueStock'];
      this.currentDescriptionPercentual = data['percentStock'];
      this.currentDescriptionNameStock = data['valueDescription1']
      console.log('Descrição valor: ' + data['valueStock']);
      console.log('Descrição percentual: ' + data['percentStock']);
      console.log('Descrição stock: ' + data['valueDescription1']);

      console.log(':)');
    }, (e) => {
      console.log('Erro api stock');
      console.log(e);
      console.log(':(');
    });
  }
  

  loadStock() {    
      this.http.get('https://serviertv-api.br1.servier.com/api/Noticia/GetCotationStock/' + window.location.href.split('?ID=').pop()).subscribe((data: any[]) => {
      //this.http.get('https://serviernewsapi-dev.azurewebsites.net/api/Noticia/GetCotationStock/' + window.location.href.split('?ID=').pop()).subscribe((data: any[]) => {

        if(data != null || data.length != 0){
          this.currentValor = Number(data[this.currentDescriptionValor]);
          this.currentPercentual = data[this.currentDescriptionPercentual];
          console.log('valor - ' + this.currentValor +' percentual -  '+ this.currentPercentual + ' :)');
        }else{
          console.log('Data null' + data)
        }
      
    }, (e) => {
      console.log('Erro api stock');
      console.log(e);
      console.log(':(');
    });
  }

  loadDescriptionQuotation1() {    
    this.http.get('https://serviertv-api.br1.servier.com/api/Noticia/GetDescriptionStock/' + window.location.href.split('?ID=').pop()).subscribe((data: any[]) => {
      //this.http.get('https://serviernewsapi-dev.azurewebsites.net/api/Noticia/GetDescriptionQuotation1/' + window.location.href.split('?ID=').pop()).subscribe((data: any[]) => {
      this.currentDescriptionQuotation1 = data['valueDescription1']
      console.log('Descrição Quotation1: ' + data['valueDescription1']);

      console.log(':)');
    }, (e) => {
      console.log('Erro api Quotation1');
      console.log(e);
      console.log(':(');
    });
  }

  loadDescriptionQuotation2() {    
    //this.http.get('https://serviertv-api.br1.servier.com/api/Noticia/GetDescriptionStock/' + window.location.href.split('?ID=').pop()).subscribe((data: any[]) => {
      this.http.get('https://serviernewsapi-dev.azurewebsites.net/api/Noticia/GetDescriptionQuotation2/' + window.location.href.split('?ID=').pop()).subscribe((data: any[]) => {
      this.currentDescriptionQuotation2 = data['valueDescription1']
      console.log('Descrição Quotation1: ' + data['valueDescription1']);

      console.log(':)');
    }, (e) => {
      console.log('Erro api Quotation2');
      console.log(e);
      console.log(':(');
    });
  }

  

/*  loadTimeZone() {    
    this.http.get('https://serviertv-api.br1.servier.com/api/Noticia/GetConfigurationLocaleDate/' + window.location.href.split('?ID=').pop()).subscribe((data: any[]) => {
      this.currentTimeZone = data['timeZone'];
      this.currentLocale = data['locale'];
      console.log(':)');
    }, (e) => {
      console.log('Erro api TimeZone');
      console.log(e);
      console.log(':(');
    });
  }*/

  loadCotationImage1() {    
    this.http.get('https://serviertv-api.br1.servier.com/api/Noticia/GetCotationImage1/' + window.location.href.split('?ID=').pop()).subscribe((data: any[]) => {
    //this.http.get('https://serviernewsapi-dev.azurewebsites.net/api/Noticia/GetCotationImage1/' + window.location.href.split('?ID=').pop()).subscribe((data: any[]) => {
      this.currentImageCotation1 = data['imageCotation1'];
      console.log(' url dollar - ' + this.currentImageCotation1 + ':)');
    }, (e) => {
      console.log('Erro api image cotation');
      console.log(e);
      console.log(':(');
    });
  }

  loadCotationImage2() {    
    this.http.get('https://serviertv-api.br1.servier.com/api/Noticia/GetCotationImage2/' + window.location.href.split('?ID=').pop()).subscribe((data: any[]) => {
      //this.http.get('https://serviernewsapi-dev.azurewebsites.net/api/Noticia/GetCotationImage2/' + window.location.href.split('?ID=').pop()).subscribe((data: any[]) => {
      this.currentImageCotation2 = data['imageCotation2'];
      console.log(' url dollar - ' + this.currentImageCotation2 + ':)');
    }, (e) => {
      console.log('Erro api image cotation');
      console.log(e);
      console.log(':(');
    });
  }
  

  loadFormatDate() {    
    this.http.get('https://serviertv-api.br1.servier.com/api/Noticia/GetConfigurationDate/' + window.location.href.split('?ID=').pop()).subscribe((data: any[]) => {
    //this.http.get('https://serviernewsapi-dev.azurewebsites.net/api/Noticia/GetConfigurationDate/' + window.location.href.split('?ID=').pop()).subscribe((data: any[]) => {
      this.currentFormatDate = data['formatDate'];
      this.currentFormatHour = data['Hour'];
      console.log('format date  - ' + this.currentFormatDate + ' format hour - ' + this.currentFormatHour + ' :)');
    }, (e) => {
      console.log('Erro api format date');
      console.log(e);
      console.log(':(');
    });
  }

  loadNewsList() {
    this.http.get('https://serviertv-api.br1.servier.com/api/Noticia/GetNoticiaTv/' + window.location.href.split('?ID=').pop()).subscribe((data: any[]) => {
    //this.http.get('https://serviernewsapi-dev.azurewebsites.net/api/Noticia/GetNoticiaTv/' + window.location.href.split('?ID=').pop()).subscribe((data: any[]) => {
      console.log('carregando noticias');
      clearTimeout(this.nextNewsUpdater);
      
      
      this.newsList = data;

      console.log('Href: ' + window.location.href.split('?ID=').pop() + ' LENGTH: ' + data);      
      
      if(this.newsList == null || this.newsList.length == 0)
      {
        console.log("Data null");
        //this.currentNewsScreen = 'https://serviernews-dev.azurewebsites.net/SVAImagens/SemNoticia.jpg';
        this.currentNewsScreen = 'https://serviertv-admin.br1.servier.com/SVAImagens/SemNoticia.jpg';
        
      }else{
        
        this.currentNewsIndex = this.newsList.length;
        console.log("currentNewsIndex: " + this.currentNewsIndex);
        //this.currentNewsScreen = 'https://serviernews-dev.azurewebsites.net/NoticiasTv/' + this.newsList[this.currentNewsIndex % this.newsList.length].Arquivo;
        this.currentNewsScreen = 'https://serviertv-admin.br1.servier.com/NoticiasTv/' + this.newsList[this.currentNewsIndex % this.newsList.length].Arquivo;
      }
      this.currentNewsIndex = -1;
      this.currentNews = null;
      console.log(data);
      console.log('Url Image: ' + this.currentNewsScreen);
      this.loadNextNews();
      //this.loadIbov();
    }, (e) => {
      console.log(e);
      console.log(':(');
    });
  }

  startUpdater() {
    const that = this; 
    this.loadCountry();   
    this.loadNameWeather();
    this.loadWeather();
    //that.loadDescriptionStock();
    that.loadDescriptionQuotation1();
    that.loadDescriptionQuotation2();
    that.loadCurrecyCotation1();
    that.loadCurrecyCotation2();
    //that.loadStock();
    that.loadCotationImage2();
    that.loadCotationImage1();
    that.loadConvertCotation1();
    that.loadConvertCotation2();    
    that.loadFormatDate();
    this.loadNewsList();     
    setInterval(() => {
      console.log('reload tela :)');
      //window.location.reload(true);
      that.loadNameWeather();
      that.loadWeather();
      that.loadCotationImage2();
    that.loadCotationImage1();
    that.loadConvertCotation1();
    that.loadConvertCotation2();
    that.loadCurrecyCotation1();
    that.loadCurrecyCotation2();
      this.loadNewsList()
    }, this.newsApiCallTimeout);
    //that.loadDescriptionStock();
    //that.loadStock();    
    /*that.loadCurrecyCotation1();
    that.loadCurrecyCotation2();*/
    /*setInterval(() => {
      that.loadCurrecyCotation1();
      that.loadCurrecyCotation2();
    }, this.currencyApiCallTimeout);*/
    setInterval(() => {
      this.currentDate = new Date();
    }, 1000);
  }

  ngOnInit() {
    registerLocaleData(pt);
    this.startUpdater();
  }
}
